@font-face {
    font-family: "Hypermarket Exp W00 Regular";
    src: url("https://db.onlinewebfonts.com/t/9add0b37496bb8dadf45434afc863f5d.eot");
    src: url("https://db.onlinewebfonts.com/t/9add0b37496bb8dadf45434afc863f5d.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/9add0b37496bb8dadf45434afc863f5d.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/9add0b37496bb8dadf45434afc863f5d.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/9add0b37496bb8dadf45434afc863f5d.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/9add0b37496bb8dadf45434afc863f5d.svg#Hypermarket Exp W00 Regular")format("svg");
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-image: url('../../bg.png');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

